import React, { useRef, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, NavLink } from "react-router-dom";
import MegaMenu from "../MegaMenu/MegaMenu";
import { useTranslation } from "react-i18next";
import "./Header.css"; // Default CSS for initial load

// IMPORTING IMAGES
import NajeebLogo from "../../images/najeeb-auto-logo.png";
import SuzukiLogo from "../../images/suzuki-logo.png";

const Header = ({ onLanguageSwitch }) => {
  const { t, i18n } = useTranslation();
  const navbarCollapseRef = useRef(null);
  const [isFixedTop, setIsFixedTop] = useState(false);

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./Header-ar.css");
    } else {
      import("./Header.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const closeNavbar = () => {
    if (
      navbarCollapseRef.current &&
      navbarCollapseRef.current.classList.contains("show")
    ) {
      navbarCollapseRef.current.classList.remove("show");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 60) {
        setIsFixedTop(true);
      } else {
        setIsFixedTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const languageToggleText = i18n.language === "ar" ? "EN" : "العربية";

  return (
    <Navbar
      expand="lg"
      className={`${isFixedTop ? "fixed-top" : ""} header-color ${
        i18n.language === "ar" ? "dir-ltr" : ""
      }`}
    >
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/" onClick={closeNavbar}>
          <div className="brand-logo">
            <img className="logo-primary" src={SuzukiLogo} alt="Suzuki Logo" />
            <img
              className="logo-secondary"
              src={NajeebLogo}
              alt="Najeeb Auto Logo"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" ref={navbarCollapseRef}>
          <Nav
            className={`${
              i18n.language === "ar" ? "ms-auto dir-rtl" : "ms-auto"
            }`}
          >
            <Nav.Link
              as={NavLink}
              className="brandNavLink"
              to="vehicles"
              onClick={closeNavbar}
            >
              {t("Vehicles")}
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="brandNavLink"
              to="offers"
              onClick={closeNavbar}
            >
              {t("Offers")}
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="brandNavLink"
              to="our-locations"
              onClick={closeNavbar}
            >
              {t("Our Locations")}
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="brandNavLink"
              to="spare-parts"
              onClick={closeNavbar}
            >
              {t("Spare Parts")}
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              className="brandNavLink"
              to="contact"
              onClick={closeNavbar}
            >
              {t("Contact")}
            </Nav.Link>
            <NavDropdown
              className="brandNavLink"
              title={t("Owners")}
              id="basic-nav-dropdown"
            >
              <div className="mega-menu-dropdown">
                <MegaMenu closeNavbar={closeNavbar} />
              </div>
            </NavDropdown>
            <Nav.Link
              as={NavLink}
              className="brandNavLink"
              to="about-us"
              onClick={closeNavbar}
            >
              {t("About Us")}
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              className="brandNavLink get-quote-header"
              to="get-quote"
              onClick={closeNavbar}
            >
              {t("Get Quote")} <i
              className={`${
                i18n.language === "ar"
                  ? "jam jam-arrow-up-left"
                  : "jam jam-arrow-up-right"
              }`}
            ></i>
            </Nav.Link>
            
            {/* <Nav.Link className="brandNavLink get-quote-header"
              href="/360-config/index.html" target="_blank"
              onClick={closeNavbar}>{t("360° Accessories Configurator")} <i
              className={`${
                i18n.language === "ar"
                  ? "jam jam-arrow-up-left"
                  : "jam jam-arrow-up-right"
              }`}
            ></i></Nav.Link>  */}
          </Nav>
          <button
            onClick={onLanguageSwitch}
            style={{
              background: "none",
              border: "none",
              display: "flex",
              alignItems: "center",
              padding: "0",
              margin: "0",
              fontSize: "16px",
            }}
          >
            <span>{languageToggleText}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-2 -2 24 24"
              width="22"
              fill="currentColor"
              style={{ marginLeft: i18n.language === "ar" ? "8px" : "0", marginRight: i18n.language !== "ar" ? "8px" : "0" }}
            >
              <path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path>
              <path d="M10 18c.448 0 1.119-.568 1.747-1.823C12.532 14.607 13 12.392 13 10c0-2.392-.468-4.607-1.253-6.177C11.119 2.568 10.447 2 10 2c-.448 0-1.119.568-1.747 1.823C7.468 5.393 7 7.608 7 10c0 2.392.468 4.607 1.253 6.177C8.881 17.432 9.553 18 10 18zm0 2c-2.761 0-5-4.477-5-10S7.239 0 10 0s5 4.477 5 10-2.239 10-5 10z"></path>
              <path d="M2 12h16v2H2v-2zm0-6h16v2H2V6z"></path>
            </svg>
          </button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
